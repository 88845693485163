export enum TourMode {
  BUS = 'Bus',
  BOAT = 'Boat',
  FEET = 'Feet',
  CAR = 'Car',
  MOTO = 'Moto',
  MOTOBUS = 'Motobus',
  BICYCLE = 'Bicycle',
  TUKTUK = 'Tuk-Tuk',
  OTHER = 'Other',
}
